import "./NavElement.css";

type Props = {
  title: string;
  dark?: boolean;
};

const NavElement: React.FC<Props> = ({ title, dark }) => {
  return (
    <div className={dark ? "nav-element-dark" : "nav-element"}>{title}</div>
  );
};

export default NavElement;

import { useCallback, useEffect, useRef, useState } from "react";

import "./Timer.css";

const MY_SKILLS = [
  "React",
  "React Native",
  "Javascript (ES6+)",
  "Typescript",
  "Redux",
  "Node.js",
  "Next.js",
  "Express.js",
  "MongoDB",
  "PostgreSQL",
];

const Timer = () => {
  const [skillIndex, setSkillIndex] = useState(0);
  const skillOpacity = useRef(1);

  const animateToNextSkill = useCallback(() => {
    console.log(skillIndex);
    setSkillIndex((skillIndex) => {
      if (skillIndex === MY_SKILLS.length - 1) return 0;
      return skillIndex + 1;
    });
  }, [skillIndex, setSkillIndex]);

  useEffect(() => {
    setTimeout(() => {
      animateToNextSkill();
    }, 5000);
  }, [animateToNextSkill]);

  return (
    <h4>
      Skilled in:{" "}
      <span style={{ opacity: skillOpacity.current }}>
        {MY_SKILLS[skillIndex]}
      </span>
    </h4>
  );
};

export default Timer;

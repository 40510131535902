import React from "react";

import Header from "../../components/Header/Header";
import NavBar from "../../components/NavBar/NavBar";

import "../../App.css";
import familyPhoto from "../../assets/family-photo.jpeg";
import Card from "../../components/Card/Card";

const About: React.FC = () => {
    return (
        <React.Fragment>
            <div className="about-heading-container">
                <Header title="About" color="black" />
                <NavBar dark={false}/>
            </div>
            <div className="about-content-container">
                <div className="image-container">
                    <img src={familyPhoto} alt="My Family" id="family-photo"/>
                </div>
                <div className="text-container">
                    <div>
                        <Card style={{width:"60vw", height:"70vh"}} isActive>
                            <div className="about-text">
                            <p>Junior software developers are a dime a dozen and well-rounded, highly skilled coders are priceless. The average new CS graduate doing an internship or still looking for that first job requires hundreds of hours of investment and expects to be paid well while he is trained. You want somebody with work experience. You want somebody who knows how to work with others. You want the skills without all the frills. Look no further. You have found your man.</p>
                            <p>I currently work for a small consulting firm in Austin, TX, building the front-end for a mobile application in React Native and Typescript. This experience has bolstered my skills not only in the stack we use, but also in collaboration through Github, Slack, and Jira. I am well-qualified to fill an entry-level role in web and native development using the extremely popular React and React Native Javascript libraries. I am very happy with Polygrok, but I am looking for something with more long-term potential and the ability to work full-time.</p>
                            <p>Computer science has always been one of my greatest interests and technology has always been a first language for me. At 14 years old, I had built my own computer and was programming on Linux. Dabbling in coding throughout and since high school led to me devoting my free time since Summer 2021 to online coursework through resources like Udemy and committing myself to making one final career change. I landed my first part-time contractual position within six months and am now ready to bring my career to the next-level.</p>
                            <p>I have always enjoyed programming and should have pursued that passion from the beginning. Maybe going to school for a CS degree would have made this transition easier, but my vast experience in construction, sales, management, and a variety of other industries now better equips to be an outstanding, more well-rounded developer. I am in all actuality a stronger candidate for an entry-level position than the average new graduate without work experience and I don't regret the time I have spent finding my way to where I am today.</p>
                            </div>
                        </Card>
                    </div>
                </div>
                {/* <ScrollToTopButton /> */}
            </div>
        </React.Fragment>
    )
}

export default About;
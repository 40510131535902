import Header from "../../components/Header/Header";
import Timer from "../../components/Timer/Timer";

import profilePic from "../../assets/profile_pic.jpeg";
import { Link } from "react-scroll";

import linkedInLogo from "../../assets/LinkedIn.png";
import githubLogo from "../../assets/GitHub.png";

const Home = () => {
  return (
    <div className="home-wrapper">
      <div className="header-container">
        <Timer />
      </div>
      <div className="home-container">
        <div className="main-content">
          <div className="profile-pic-container">
            <img
              src={profilePic}
              alt="Brandon Pelton-Cox"
              className="profile-pic"
            />
          </div>
          <div>
            <Header
              title="Brandon Pelton-Cox"
              subtitle="Full-Stack Software Engineer"
              color="black"
            />
            <div className="button-container">
              <Link
                to="about"
                activeClass="nav-element-active"
                spy={true}
                hashSpy={true}
                smooth={true}
                duration={500}
                delay={250}
              >
                <button className="main-nav">About Me</button>
              </Link>
              {/* <Link
              to="https://www.github.com/bpeltonc"
              activeClass="nav-element-active"
              spy={true}
              hashSpy={true}
              smooth={true}
              duration={500}
              delay={250}
            > */}
              <button className="main-nav">
                <a href="https://www.github.com/bpeltonc">View My Work</a>
              </button>
              {/* </Link> */}
              {/* <Link
              to="contact"
              activeClass="nav-element-active"
              spy={true}
              hashSpy={true}
              smooth={true}
              duration={500}
              delay={250}
            >
              <button className="main-nav">Contact Me</button>
            </Link> */}
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <a href="https://linkedin.com/in/bpeltoncox">
          <img className="img-link" src={linkedInLogo} alt="linkedIn logo" />
        </a>
        <a href="https://github.com/bpeltonc">
          <img className="img-link" src={githubLogo} alt="github logo" />
        </a>
      </div>
    </div>
  );
};

export default Home;

import { motion } from "framer-motion";

import "./Card.css"

type Props = {
    isActive?: boolean;
    style: any;
    children?: React.ReactNode;
}

const Card: React.FC<Props> = ({isActive, style, children}) => {

    return (
        (isActive) ? 
        <motion.div
            animate={{...style}}
            transition={{ease: "easeIn", duration: 2}}
            className="card-container"
            // style={{...style}}
            >
                {children}
        </motion.div> : 
        <motion.div className="card-container"></motion.div>
    );
}

export default Card;
import NavElement from "./NavElement/NavElement";
import { Link, animateScroll as scroll } from "react-scroll";

import "./NavBar.css";

type Props = {
  dark: boolean;
};

const NavBar = ({ dark }: Props) => {
  return (
    <nav className="navBar">
      <Link
        to="home"
        activeClass="nav-element-active"
        spy={true}
        hashSpy={true}
        smooth={false}
        duration={500}
        delay={250}
        onClick={scroll.scrollToTop}
      >
        <NavElement title="Home" />
      </Link>
      <Link
        to="about"
        activeClass="nav-element-active"
        spy={true}
        hashSpy={true}
        smooth={true}
        duration={500}
        delay={250}
      >
        <NavElement title="About" />
      </Link>
      {/* <Link
        to="portfolio"
        activeClass="nav-element-active"
        spy={true}
        hashSpy={true}
        smooth={true}
        duration={500}
        delay={250}
      > */}
      <a href="https://www.github.com/bpeltonc">
        <NavElement title="Portfolio" />
      </a>
      {/* </Link> */}
      {/* <Link
        to="contact"
        activeClass="nav-element-active"
        spy={true}
        hashSpy={true}
        smooth={true}
        duration={500}
        delay={250}
      >
        <NavElement title="Contact" />
      </Link> */}
    </nav>
  );
};

export default NavBar;
